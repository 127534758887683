import { useState, useEffect } from 'react';
import API from './../../../api';
import { useTranslation } from 'react-i18next';
import './admin-customer-special-prices.css';
// Import des icônes de React Icons
import { FaPlus, FaTrash, FaTimes } from 'react-icons/fa';

function AdminCustomerSpecialPrices({ userId, onSaved }) {
  const { t } = useTranslation();
  const [specialPrices, setSpecialPrices] = useState([]);
  const [newSpecialPrice, setNewSpecialPrice] = useState({ spProdRef: '', spPrice: '' });
  const [formStatus, setFormStatus] = useState('')
  useEffect(() => {
    if (userId !== '') {
      fetchSpecialPrices();
    }
  }, [userId]);
  /*
  useEffect(() => {
    onChange(specialPrices);
  }, [specialPrices]);
  */
  const fetchSpecialPrices = async () => {
    const response = await API.get('/admin/customer/' + userId + '/specialPrices');
    setSpecialPrices(response.data);
  };

  const [addingError, setAddingError] = useState('');

  const handleNewSpecialPriceChange = (event) => {
    setNewSpecialPrice({ ...newSpecialPrice, [event.target.name]: event.target.value });
  };

  const handleCreateSpecialPrice = () => {
    setAddingError('');
    if (newSpecialPrice.spProdRef !== '' && newSpecialPrice.spPrice !== '') {
      const idx = specialPrices.findIndex(sp => sp.spProdRef === newSpecialPrice.spProdRef.trim());
      if (idx > -1) {
        setAddingError('Un prix spécial existe déjà pour ce client et pour cette référence.');
      } else {
        setSpecialPrices([{ ...newSpecialPrice, spUserId: userId, spStatus: "active" }, ...specialPrices]);
        setNewSpecialPrice({ spProdRef: '', spPrice: '' });
      }
    }
  };

  const handleDeleteSpecialPrice = (spId) => {
    setSpecialPrices(specialPrices.filter(sp => sp.spId !== spId));
  };
  const handleSpecialPriceChange = (id, event) => {
    const specialPricesCopy = specialPrices.map(sp => {
      if (sp.spId === id) {
        return { ...sp, [event.target.name]: event.target.value };
      }
      return sp;
    });
    setSpecialPrices(specialPricesCopy);
  };
  const handleSave = async () => {
    const response = await API.put('/admin/customer/' + userId + '/specialPrices', specialPrices);
    onSaved(response.data)
  }
  return (
    <div id="CustomerSpecialPrices">
      <div className="d-flex justify-content-end  mb-3">
        <button className='btn btn-green' onClick={handleSave} >
          {formStatus == 'saving' && <span class="spinner-border spinner-border-sm me-2" aria-hidden="true"></span>}
          Sauvegader
        </button>

      </div>
      <table className='table table-sm table-striped'>
        <thead>
          <tr>
            <th style={{ width: '250px' }}>Référence</th>
            <th style={{ width: '100px' }}>Prix remisé</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <input type="text" value={newSpecialPrice.spProdRef} name="spProdRef" className='form-control form-control-sm text-center' onChange={handleNewSpecialPriceChange} />
            </td>
            <td>
              <input type="number" value={newSpecialPrice.spPrice} name="spPrice" className='form-control form-control-sm text-center' onChange={handleNewSpecialPriceChange} />
            </td>
            <td className='text-end'>
              <button onClick={handleCreateSpecialPrice} className="btn btn-sm btn-green">
                <FaPlus style={{ position: 'relative', top: "-2px" }} />
              </button>
            </td>
          </tr>
          {addingError !== '' && (
            <tr>
              <td colSpan={3}>
                <div className='alert alert-warning p-1 text-center d-flex'>
                  <div className='flex-grow-1'>
                    {addingError}
                  </div>
                  <span onClick={() => setAddingError('')} className='pointer'>
                    <FaTimes />
                  </span>
                </div>
              </td>
            </tr>
          )}
          {specialPrices?.map((specialPrice, idx) => (
            <tr key={idx}>
              <td>
                <input type="text" value={specialPrice.spProdRef} name="spProdRef" className='form-control form-control-sm text-center' onChange={e => handleSpecialPriceChange(specialPrice.spId, e)} />
              </td>
              <td>
                <input type="text" value={specialPrice.spPrice} name="spPrice" className='form-control form-control-sm text-center' onChange={e => handleSpecialPriceChange(specialPrice.spId, e)} />
              </td>
              <td className='text-end'>
                <button onClick={() => handleDeleteSpecialPrice(specialPrice.spId)} className="btn btn-sm btn-outline-secondary">
                  <FaTrash />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {false && <pre>{JSON.stringify(specialPrices, null, true)}</pre>}
    </div>
  );
}

export default AdminCustomerSpecialPrices;
