// Header.js
import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// import Header_User from './../user/Header_User';
// import Header_Cart from './../cart/Header_Cart';
import { Link } from 'react-router-dom';
// import './header.css';
import API from './../../api';

const AdminHeader = React.memo(() => {
  const user = useSelector((state) => state.user);
  const { t } = useTranslation();
  const backoffice_url = window.sessionStorage.getItem('backoffice_url');
  return (
    <header className="App-header" id="header">
      <div id="header-main">
        <div className='row'>
          <div className='col' id='header-main-left'>
            <Link className='logo' to="/extradmin">
              Backoffice
              {/* <img src='/img/logo.png' alt='amd jaeger' /> */}
            </Link>
          </div>
          <div className='col' id='header-main-center'>
            Menu Adminsitration
          </div>
          <div className='col' id='header-main-right'>
            <a href={backoffice_url}>RETOUR BACKOFFICE</a>
          </div>
        </div>
      </div>
    </header>
  );
});

export default React.memo(AdminHeader);
