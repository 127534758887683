import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import API from '../../api';
function AdminHome() {
  return (<div className='container'>
    <div className='row'>
      <div className='col-sm-4 mb-5 text-center'>
        <Link to="/extradmin/customers">
          CLIENTS
        </Link>
      </div>
      <div className='col-sm-4 mb-5 text-center'>
        <Link to="/extradmin/orders">
          COMMANDES
        </Link>
      </div>
      <div className='col-sm-4 mb-5 text-center'>
        <dl>
          <dt>Catalogues</dt>
          <dd>


            <Link to="/extradmin/products?prodCatalogue=Faisceau">
              Faisceaux
            </Link>



          </dd>
          <dd>        <Link to="/extradmin/products">
            ---
          </Link>
          </dd>
          <dd>        <Link to="/extradmin/products">
            ---
          </Link>
          </dd>

        </dl>
        <Link to="/extradmin/orders">

        </Link>
      </div>
    </div>

  </div>);

}
export default AdminHome;