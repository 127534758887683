// ProductRel.js
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { IoOpenOutline } from "react-icons/io5";
import API from './../../api';
import OrderItem from './../orders/OrderItem';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import './product-rel.css';
function ProductRel({ parentRef, onRelSelected }) {
  const [products, setProducts] = useState([]);
  useEffect(() => {
    if (parentRef) {
      API.get(`/product/related/${parentRef}`).then(response => {
        setProducts(response.data);
      })
    }
  }, [parentRef]);
  return (
    <div className="product-rel">
      <div className='container text-start'>
        <h2 className='text-center mb-3'>Kits, boitiers & prises liés</h2>
        <table className='table table-striped'>
          <thead>
            <tr></tr>
          </thead>
          <tbody>
            {products.length > 0 && products.map((product, idx) => (
              <tr key={idx}>
                <td className='reference'>
                  {product.prodRef}
                </td>
                <td className='titre'>
                  {product.prodTitre}
                </td>
                <td className=''><IoOpenOutline className="pointer" onClick={(e) => onRelSelected(product)} /></td>
              </tr>
            ))}
            {products.length === 0 &&
              <div className='pt-5 text-center small'>
                Aucun produit trouvé.<br />
                Nous vous invitons à nous contacter.
              </div>
            }
          </tbody>
        </table>
        {false && <pre>{JSON.stringify(products[0], null, 2)}</pre>}
      </div>
    </div >
  );
}
export default ProductRel;
