import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux'; // Importe useSelector
import { setUser } from './../../redux/userSlice';
import { debug } from '../../utils';
import { Form, Button, Alert, InputGroup } from 'react-bootstrap'; // Importez les composants React Bootstrap nécessaires
import API from '../../api';
import './user-form.css';
function UserLostPwd({ onTabSwitch, options = null, updateOptions }) {
  const config = useSelector((state) => state.config);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [recoveryCodeParts, setRecoveryCodeParts] = useState(['', '', '', '', '', '']);
  const [recoveryToken, setRecoveryToken] = useState('');
  const [codeInputs, setCodeInputs] = useState(0);
  const [submission, setSubmission] = useState({ status: '', message: ' ' });
  const [step, setStep] = useState(1);
  const numbersInputRefs = useRef([null, null, null, null, null, null]);
  useEffect(() => {
    if (step == 1) {
      setRecoveryCodeParts(['', '', '', '', '', '']);
      setCodeInputs(0);
    }
  }, [step]);
  const handleRecoverySubmit = async (event) => {
    if (event) {
      event.preventDefault();
    }
    //try {
    switch (step) {
      case 1:
        const emailRegex = new RegExp(config.form_rules.email.regexp.slice(1, -1));
        if (email && email.match(emailRegex)) {
          setSubmission({ status: 'pending' });
          const response = await API.post('/user/lostPwd', { email: email });
          setSubmission({ status: "success", message: response.data.message });
          setRecoveryToken(response.data.token)

          setStep(2);
        } else {
          setSubmission({ status: "error", message: 'Adresse email invalide' });
        }
        break;
      case 2:
        const postData = {
          token: recoveryToken, recoveryCode: recoveryCodeParts.join('')
        };
        let codeInputsCopy = codeInputs + 1;
        setCodeInputs(codeInputsCopy);
        if (postData.recoveryCode.length === 6) {
          setSubmission({ status: 'pending' });
          const response = await API.post('/user/lostPwd', postData);
          if (response.data === true) {
            // dispatch(setUser({ isSignedIn: true }));
            updateOptions({ ...postData });
            onTabSwitch('newPwd');
          } else {
            if (codeInputsCopy < 3) {
              setSubmission({ status: "error", message: response.data.message });
            } else {
              API.post('/user/lostPwd/abort', postData);
              setSubmission({ status: "error", message: "Trop d'erreurs. Veuillez redemander un code." });
            }
          }
        }
        break;
    }
    //} catch (error) {
    //  console.log(error)
    //  setSubmission({ code: error.response.data.error.code, status: "error", message: error.response.data.error.message });
    //};
  }
  const handleNumberChange = (event, index) => {
    let { name, value } = { ...event.target }
    value = value.trim();
    if (index === 0 && value.length === 6) {
      setRecoveryCodeParts(value.split(''));
      handleRecoverySubmit();
    } else {
      const codeCopy = [...recoveryCodeParts];
      codeCopy[index] = value.charAt(0);
      console.log(codeCopy)
      setRecoveryCodeParts(codeCopy);
      if (index < codeCopy.length - 1) {
        numbersInputRefs.current[index + 1].focus();
      }
    }
    setSubmission({ status: "" });
  }
  return (
    <div className='user-form-container'>
      <Form onSubmit={event => event.preventDefault()}>
        <h1>Récupération de mot de passe</h1>
        {step == 1 &&
          <>
            <p>Pour que vous puissiez définir un nouveau mot de passe, un code va vous être envoyé par courriel.</p>
            <Form.Group className="form-group">
              <Form.Label required>Adresse e-mail attachée à votre compte : </Form.Label>
              <Form.Control
                type="email"
                placeholder=""
                value={email}
                onChange={(e) => { setEmail(e.target.value); setSubmission({ status: "" }) }}
                disabled={submission.status == 'pending'}
              />
            </Form.Group>
          </>
        }

        {(step == 2 && recoveryCodeParts) &&
          <>
            <p>
              Indiquez ci-dessous le code à six chiffres que vous avez reçu par courriel.<br />
              <em className='small'>Pensez à vérifier dans les <u>courriers indésirables</u> si vous vous ne trouvez pas ce courriel dans la boite de reception de votre application de messagerie.</em>
            </p>
            <InputGroup className="form-group d-flex flex-wrap justify-content-between" date-code="927520">
              {Array(6).fill().map((_, index) => (
                <Form.Control
                  key={index}
                  ref={(el) => numbersInputRefs.current[index] = el}
                  type="number"
                  placeholder=""
                  maxLength={1}
                  value={recoveryCodeParts[index]}
                  onChange={(event) => handleNumberChange(event, index)}
                  disabled={submission.status === 'pending'}
                />
              ))}
            </InputGroup>
          </>
        }
        <Alert
          className='py-2 px-3 small'
          variant={submission.status == 'success' ? 'success' : 'danger'}
          dismissible={true}
          transition={true}
          show={submission.status === 'error'}
          onClose={() => setSubmission({ status: '' })}
        >
          {submission.message}
        </Alert>
        <Form.Group className="form-group-submit">
          {step == 1 || codeInputs < 3 ?
            (
              <Button variant="warning" type="button" disabled={submission.status == 'pending' || submission.status == 'success'}
                onClick={handleRecoverySubmit}
              >
                {step == 1 ? ('Envoyer le code de récupération') : ('Valider')}
              </Button>
            ) : (
              <Button variant="warning" type="button"
                onClick={e => setStep(1)}
              >Demander un autre code
              </Button>
            )
          }
        </Form.Group>
        <p className='small pt-4 pb-5'>
          <span className='pointer d-inline-block' onClick={e => onTabSwitch('')}>Annuler</span>
          {(submission.code && submission.code === 404 && options?.userType)
            &&
            <strong className='pointer d-inline-block ms-5' onClick={e => onTabSwitch('')}>Créer un compte</strong>
          }
        </p>
      </Form>

    </div>
  );
}
export default UserLostPwd;

