
import React from 'react';
import { useTranslation } from 'react-i18next';
import './products-list-header.css';
const ProductsListHeader = ({ catalog }) => {
  const { t } = useTranslation();
  return (
    <thead className='products-list-header'>
      <tr>
        {catalog != 'kit' &&
          <th className='type'>Premium <br />Standard</th>
        }
        <th className='ref'>{t('Ref')}</th>

        <th className='constructor'>
          {catalog != 'kit' ?
            <>{t('Constructor')}<br />{t('Model')}</>
            :
            <>  {t('Description')}</>
          }
        </th>
        {catalog != 'kit' &&
          <th className='years'>{t('Years')}</th>
        }
        <th className='type'>{t('Type')}</th>
        <th className='price'>{t('HT')} €</th>
        <th className='stocks'>{t('Stocks')}</th>
        <th className='functions'></th>
      </tr>
    </thead>)
}
export default ProductsListHeader;