import React, { useEffect, useState } from 'react';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'; // Importe useSelector
import { fetchConfig } from './redux/configSlice';
import { updateUser, clearUser } from './redux/userSlice';
import API from './api';
// Layouts
import AppLayout from './layouts/AppLayout';
// Routes Components

import HomeDefault from './components/homes/HomeDefault';
import HomeMain from './components/homes/HomeMain';
import Catalog from './components/products/Catalog';
import Cart from './components/cart/Cart';
// import UserSignIn from './components/user/UserSignIn';
// import UserLostPwd from './components/user/UserLostPwd';
// import UserPdwRecovery from './components/user/UserPdwRecovery';
// import UserProfile from './components/user/UserProfile';
import DeliverySelect from './components/deliveries/DeliverySelect';
import Orders from './components/orders/Orders';
import Faq from './components/faq/Faq';
import Thread from './components/faq/Thread';
import AdminHome from './components/homes/AdminHome';
import AdminCustomersList from './components/customers/admin/AdminCustomersList';
import AdminOrdersList from './components/orders/admin/AdminOrdersList';
import AdminProductsList from './components/products/admin/AdminProductsList';
// Styles 
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './css/app.css';
// Route Definitions
const routes = [
  { path: "/", element: <HomeDefault /> },
  // { path: "/user/signIn", element: <UserSignIn /> },
  // { path: "/user/lostPwd", element: <UserLostPwd /> },
  // { path: "/user/pwd_recovery", element: <UserPdwRecovery /> },
  // { path: "/user/profile", element: <UserProfile />, requireAuth: true },
  { path: "/home", element: <HomeMain />, requireAuth: true },
  { path: "/cart", element: <Cart />, requireAuth: true },
  { path: "/catalog", element: <Catalog />, requireAuth: true },
  { path: "/orders", element: <Orders />, requireAuth: true },
  { path: "/faq", element: <Faq />, requireAuth: true },
  { path: "/faq/:ref", element: <Thread />, requireAuth: true },
  // { path: "/product/:id", element: <Product />, requireAuth: true },
  { path: "/deliverySelect/:orderId", element: <DeliverySelect />, requireAuth: true },
  { path: "/extradmin", element: <AdminHome />, requireAdmin: true },
  { path: "/extradmin/customers", element: <AdminCustomersList />, requireAdmin: true },
  { path: "/extradmin/orders", element: <AdminOrdersList />, requireAdmin: true },
  { path: "/extradmin/products", element: <AdminProductsList />, requireAdmin: true },



];
function App() {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [allowed, setAllowed] = useState(false);
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const adminAuthToken = params.get('adminAuthToken');
    if (adminAuthToken) {
      checkToken(adminAuthToken, params.get('userId'));
    } else {
      setAllowed(true);
    }
    // Setup message listener
    const handleMessage = (event) => {
      //      console.log('Received message:', event.data.type);
      if (event.data && event.data.type === 'userSignOut') {
        dispatch(clearUser());
      }
    };
    window.addEventListener("message", handleMessage);
    // Cleanup listener on unmount
    return () => window.removeEventListener("message", handleMessage);


  }, []);
  useEffect(() => {
    dispatch(fetchConfig());
  }, [dispatch]);
  const checkToken = async (adminAuthToken, userId) => {

    let querystring = 'adminAuthToken=' + adminAuthToken;
    querystring += '&userId=' + userId;

    console.log('/admin/signin?' + querystring);


    const response = await API.get('/admin/signin?' + querystring);
    if (response.data.isAdmin == true) {
      setAllowed(true)
      window.localStorage.setItem('adminToken', response.data.token);
      dispatch(updateUser(response.data));
      window.sessionStorage.setItem('backoffice_url', '/appAdmin');

      window.location.href = '/extradmin';
    } else {
      window.location.href = '/';
    }
  }
  let windowHeight = document.documentElement.scrollHeight
  if (window !== window.parent) {
    /*
    window.setInterval(() => {
      var element = document.getElementById('root');
      var rect = element.getBoundingClientRect();
      if (windowHeight != rect.height) {
        windowHeight = rect.height;
        window.parent.postMessage({ windowHeight: windowHeight }, 'https://laffairearepasser.fr');
      }
 
    }, 1000);
    */
  }





  /*
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [allowed, setAllowed] = useState(false);
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const adminAuthToken = params.get('adminAuthToken');
    if (adminAuthToken) {
      checkToken(adminAuthToken, params.get('userId'));
    } else {
      setAllowed(true);
    }
  }, []);
  const checkToken = async (adminAuthToken, userId) => {
    let querystring = 'adminAuthToken=' + adminAuthToken;
    querystring += '&userId=' + userId;
    const response = await API.get('/admin/signin?' + querystring);
    if (response.data.isAdmin == true) {
      setAllowed(true)
      dispatch(updateUser(response.data));
      window.sessionStorage.setItem('backoffice_url', document.referrer);
    } else {
      window.location.href = '/';
    }
  }
  const getBasename = (path) => {
    const res = path.substr(0, path.lastIndexOf('/'));
    console.log(path, res);
    return res;
  };
*/

  /* basename={getBasename(window.location.pathname)} */
  return (
    <>
      {allowed &&
        <Router>
          <Routes>
            {routes.map((route) => (
              <Route key={route.path}
                path={route.path}
                element={<AppLayout requireAuth={route.requireAuth} requireAdmin={route.requireAdmin} >
                  {route.element}
                </AppLayout>}
              />
            ))}
          </Routes>
        </Router>
      }
    </>
  );
}
export default App;
