
import { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { fetchUser } from './../../redux/userSlice';
import './home-main.css';
import ProductSearchBar from './../products/ProductSearchBar';
import ProductModal from '../products/ProductModal';
const HomeMain = () => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [showProductModal, setShowProductModal] = useState(false);
  const handleProductSelect = (product) => {
    console.log(product)
    setSelectedProduct(product)
  }
  useEffect(() => {
    if (selectedProduct) {
      setShowProductModal(true);
    }
  }, [selectedProduct]);
  const handleCloseModal = () => {
    setSelectedProduct(null)
    setShowProductModal(false)
  }
  return (
    <div id="home-main">
      <div className='container'>
        <h1>Bienvenue dans l'extranet clients</h1>
        <div className='form-group'>
          <ProductSearchBar onSelect={handleProductSelect} />
        </div>
        <div className="row text-center home-blocs">
          <div className='col-sm-4'>
            <Link to="/catalog">Catalogues</Link>
          </div>
          <div className='col-sm-4'>
            <Link to="/datasheets">Fiches Techniques</Link>
          </div>
          <div className='col-sm-4'>
            <Link to="/faq">Forum / Faq</Link>
          </div>
        </div>
        {showProductModal && <ProductModal product={selectedProduct} onClose={handleCloseModal} />}
      </div>
    </div>
  )
};
export default HomeMain;
