

import React, { useEffect, useState, useRef } from 'react';

import { useTranslation } from 'react-i18next';

import { remove as removeDiacritics } from 'diacritics';
import API from './../../../api';
import { Link, useNavigate } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import AdminCustomerEdit from './AdminCustomerEdit';
import AdminCustomerSpecialPrices from './AdminCustomerSpecialPrices';
import './admin-customers-list.css';
import { debug } from './../../../utils';
import './../../../css/tables.css';
import { FaSearch } from 'react-icons/fa';
import { MdEdit } from 'react-icons/md';
import { MdAttachMoney } from 'react-icons/md';

function AdminCustomerList() {
  const [customers, setCustomers] = useState([]);
  const [editedCustomer, setEditedCustomer] = useState(null);


  const [modalContent, setModalContent] = useState('');

  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [filters, setFilters] = useState({ globalFilter: 'SARAN', catalogs: ['Faisceau', 'Attelage', 'Kit'] });
  const [order, setOrder] = useState({ key: 'userCompName', direction: 'asc' });

  const { t } = useTranslation();
  useEffect(() => {
    try {
      API.get('/admin/customers').then(response => {
        setCustomers(response.data);
      });
    } catch (e) { }
  }, []);

  useEffect(() => {
    applyFilters();
  }, [customers, filters, order]);
  useEffect(() => {
    if (modalContent === '') {
      setEditedCustomer(null)
    }
  }, [modalContent]);
  const setOrderKey = (key) => {
    let direction = 'asc';
    if (order.key === key) {
      direction = order.direction === 'asc' ? 'desc' : 'asc';
    }
    setOrder({ key, direction });
  };

  const applyFilters = () => {
    const filteredCustomers = customers
      .filter(cust => {
        // Filtrage global par nom, prénom ou société
        if (filters.globalFilter) {
          const searchValue = filters.globalFilter.toLowerCase();
          const compName = removeDiacritics(cust.userCompName || '').toLowerCase();
          const name = removeDiacritics(cust.userName || '').toLowerCase();
          const firstName = removeDiacritics(cust.userFirstname || '').toLowerCase();
          if (!(
            compName.includes(searchValue) ||
            name.includes(searchValue) ||
            firstName.includes(searchValue)
          )) {
            return false;
          }
        }
        // Filtrage par les catalogues sélectionnés
        if (filters.catalogs.length === 0) {
          return false; // Si aucun catalogue n'est sélectionné, on affiche aucun client
        }

        return filters.catalogs.some(catalog => cust.userCatalogs?.includes(catalog));


      })
      .sort((a, b) => {
        const aValue = a[order.key] ? a[order.key].toString().toLowerCase() : '';
        const bValue = b[order.key] ? b[order.key].toString().toLowerCase() : '';

        return order.direction === 'asc'
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      });

    setFilteredCustomers(filteredCustomers);
  };

  const handleGlobalFilterChange = (e) => {
    setFilters({ ...filters, globalFilter: removeDiacritics(e.target.value).toLowerCase() });
  };

  const handleCatalogChange = (e) => {
    const catalog = e.target.value;
    const isChecked = e.target.checked;

    if (isChecked) {
      setFilters({ ...filters, catalogs: [...filters.catalogs, catalog] });
    } else {
      setFilters({ ...filters, catalogs: filters.catalogs.filter(c => c !== catalog) });
    }
  };
  const handleEditCustomer = (customer) => {
    setModalContent('customer');

    setEditedCustomer(customer);
  }
  const handleEditSpecialPrices = (customer) => {
    setModalContent('specialPrices');
    setEditedCustomer(customer);
  }
  const handleCustomerCreated = (customer) => {
    setCustomers([...customers, customer]);
  }
  const handleCustomerUpdated = (customer) => {
    setCustomers(customers.map(cust => cust.userId === customer.userId ? customer : cust));

    if (customer.userStatus.startsWith('supp')) {
      setModalContent('');
    }
  }
  const handleOnModalHide = () => {
    setModalContent('');

  }
  return (
    <div id="CustomersList">
      <div className="container">
        <h1>{t('Customers list')}</h1>
        <div className="row mb-3">
          <div className="col-md-6">
            <div className="input-group">
              <span className="input-group-text">
                <FaSearch />
              </span>
              <input
                type="text"
                placeholder="Rechercher par nom, prénom ou société"
                value={filters.globalFilter || ''}
                onChange={handleGlobalFilterChange}
                className="form-control"
              />
            </div>
          </div>
          <div className="col-md-6 d-flex flex-row align-content-center justify-content-end flex-wrap">
            <label className="form-check d-inline-block me-3">
              <input
                className="form-check-input"
                type="checkbox"
                value="Faisceau"
                id="catalogFaisceau"
                onChange={handleCatalogChange}
                checked={filters.catalogs.includes('Faisceau')}
              />
              Faisceaux
            </label>
            <label className="form-check  d-inline-block  ms-4">
              <input
                className="form-check-input"
                type="checkbox"
                value="Attelage"
                id="catalogAttelage"
                onChange={handleCatalogChange}
                checked={filters.catalogs.includes('Attelage')}
              />
              Attelages
            </label>
            <label className="form-check  d-inline-block  ms-4">
              <input
                className="form-check-input"
                type="checkbox"
                value="Kit"
                id="catalogKit"
                onChange={handleCatalogChange}
                checked={filters.catalogs.includes('Kit')}
              />
              Kits
            </label>
          </div>
        </div>
        <table className="table table-striped table-sm">
          <thead>
            <tr>
              <th>Email</th>
              <th onClick={() => setOrderKey('userName')}>Nom</th>
              <th onClick={() => setOrderKey('userFirstname')}>Prénom</th>
              <th onClick={() => setOrderKey('userCompName')}>Société</th>
              <th>Ville</th>
              <th>Téléphone</th>
              <th>Catalogues</th>
              <th colspan={2}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredCustomers.map(customer => (
              <tr key={customer.userId}>
                <td>{customer.userEmail}</td>
                <td>{customer.userName}</td>
                <td>{customer.userFirstname}</td>
                <td>{customer.userCompName}</td>
                <td>{customer.userCity}</td>
                <td>{customer.userPhone}</td>
                <td>{customer.userCatalogs?.join(', ')}</td>
                <td><button className="btn btn-sm btn-outline-secondary" onClick={e => handleEditCustomer(customer)}><MdEdit size={18} /></button></td>
                <td><button className="btn btn-sm  btn-outline-secondary" onClick={e => handleEditSpecialPrices(customer)}><MdAttachMoney size={20} /></button></td>
              </tr>

            ))}
          </tbody>
        </table>
      </div>
      {(modalContent !== '') &&
        <Modal show={true} onHide={handleOnModalHide} className="customer-modal modal-lg" keyboard={true} >
          <Modal.Header closeButton>
            <h4>
              {editedCustomer.userId === '' ? <>Nouveau client</> :
                <>
                  {editedCustomer.userName} {editedCustomer.userFirstname}
                  {editedCustomer.userCompName?.trim() !== '' && <> / {editedCustomer.userCompName}</>}
                </>
              }
            </h4>
          </Modal.Header>
          <Modal.Body>
            {modalContent === 'customer' &&
              <AdminCustomerEdit customer={editedCustomer} onCreated={handleCustomerCreated} onUpdated={handleCustomerUpdated} />
            }
            {modalContent === 'specialPrices' &&
              <AdminCustomerSpecialPrices userId={editedCustomer.userId} onSaved={e => { setModalContent('') }} />
            }

          </Modal.Body>
          <Modal.Footer>
          </Modal.Footer>
        </Modal>
      }
    </div >
  );
}

export default AdminCustomerList;
