// DeliverySelect.js
import { useState, useEffect } from 'react'; import { useTranslation } from 'react-i18next';
import API from './../../api';
import Swal from 'sweetalert2'
import { useParams, useNavigate } from 'react-router-dom';
import './delivery-select.css';
import 'sweetalert2/dist/sweetalert2.min.css';
import DeliveriesListItem from './DeliveriesListItem';
import DeliveryForm from './DeliveryForm';
import OrderSummary from './../orders/OrderSummary';
import { useDispatch, useSelector } from 'react-redux';
import { loadCart } from '../../redux/cartSlice';
import { debug } from '../../utils';
function DeliverySelect({ item, onUpdate, onClick }) {
  const dispatch = useDispatch();
  const { orderId } = useParams();
  const [deliveries, setDeliveries] = useState([]);
  const [delivery, setDelivery] = useState({ daId: '' });
  const [order, setOrder] = useState({ orderId: orderId, orderDelivryId: '', orderInternalRef: '' });
  const cart = useSelector((state) => state.cart);
  const { t } = useTranslation();
  const navigate = useNavigate();
  useEffect(() => {
  }, []);
  useEffect(() => {
    if (cart.loaded) {
      if (cart.items.length == 0) { // le panier est vide
        if (order.orderId == 'current') { // si il s'agit de la commande en cours
          navigate('/');
        }
      } else {
        try {
          API.get('/deliveries').then(response => {
            setDeliveries(response.data);
          })
        } catch (e) {
        }
      }
    }
  }, [cart]);

  const confirmDeleteItem = (item) => {
    Swal.fire({
      grow: false,
      html: t('Confirm deletion?'),
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonText: t('Yes'),
      cancelButtonText: t('No'),
      showConfirmButton: true,
      customClass: {
        htmlContainer: 'pt-5',
        confirmButton: 'btn-sm btn-green me-5',
        cancelButton: 'btn-sm',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        setDeliveries(deliveries.filter(delivery => delivery.daId !== item.daId));
        API.delete(`/delivery/${item.daId}`)
      } else if (result.isDismissed) {
      }
    });
  };
  const handleDeliverySave = (delivery) => {
    setOrder({ ...order, orderDelivryId: delivery.daId, delivery: delivery });
    setDelivery(delivery);
    document.querySelector('.summary').scrollIntoView({ behavior: 'smooth' });
  }
  const handleItemClick = (action, item) => {
    switch (action) {
      case 'select':
        setOrder({ ...order, orderDelivryId: item.daId, delivery: item });
        setDelivery(item);
        document.querySelector('.summary').scrollIntoView({ behavior: 'smooth' });
        break;
      case 'delete':
        confirmDeleteItem(item)
        break;
    }
  }
  const handleOrderChange = (data) => {
    setOrder({ ...order, ...data });
  }
  const handleOrderSaved = (data) => {
    setOrder({ ...order, ...data });
    window.setTimeout(() => {
      dispatch(loadCart);
    }, 500);
  }
  return (
    <div className="delivery-select">
      <div className='container'>
        {cart.items.length > 0 && <>
          <h1>Adresse de livraison</h1>
          <div className='row'>
            <div className='col-md-6 col-create mb-4'>
              <h2 className='mb-3'>Créer une adresse de livraison</h2>
              <div className='form-wrapper p-3'>
                <DeliveryForm onSave={handleDeliverySave} disabled={order.orderStatus.startsWith('val')} />
              </div>
            </div>
            <div className='col-md-6'>
              <h2 className='mb-3'>Utiliser une adresse connue</h2>
              {deliveries.map((item, key) =>
                (<DeliveriesListItem key={key} item={item} onClick={handleItemClick} selected={item.daId == delivery.daId} />)
              )}
            </div>
          </div>
          <hr />
        </>}
        <div className=" summary  pt-5 mb-5 pb-5 ">
          {order.orderDelivryId != '' && <>
            <OrderSummary
              order={order}
              onChange={handleOrderChange}
              onSaved={handleOrderSaved}
            />
          </>}
        </div>
        {debug(order)}
      </div>
      <div className="pt-5 pb-5 mb-5 mb-5"></div>
    </div >
  );
}
export default DeliverySelect;
