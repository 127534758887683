import React, { useEffect, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import { debug } from '../../../utils';
import { AxiosError } from 'axios'
const CustomAlert = ({ input, onClose = null }) => {
  const [variant, setVariant] = useState('');
  const [message, setMessage] = useState('');
  useEffect(() => {
    if (input) {
      if (input instanceof Error) {
        setVariant('danger');
        setMessage(input.message);
      }
      if (input instanceof AxiosError) {
        setVariant('danger');
        setMessage(input.response.data.error.message);
      }
      if (input.status && input.status == 200) {
        setVariant('success');
        setMessage(input.message);
      }
    } else {
      setVariant('');
    }
  }, [input])
  return (
    <>
      {variant != '' &&
        <Alert variant={variant} dismissible className='small py-2 px-3' onClose={onClose}>
          {message}
        </Alert>
      }
    </>
  );

};

export default CustomAlert;
