import React, { useEffect, useState, useRef } from 'react';
import API from './../../../api';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import './admin-products-list.css';
import Catalog from './../../products/Catalog'
function AdminProductList() {
  const { t } = useTranslation();

  return (
    <div id='AdminProductList'>
      <div className='container'>
        <div className="d-flex flex-wrap">
          <div className="col-md-3"></div>
          <div className="col-md-6">
            <h2 className="section-title">Gestion des produits</h2>
          </div>
          <div className="col-md-3 text-end">
            <button className="btn btn-green btn-sm">Ajouter</button>
          </div>
        </div>
        <Catalog isAdmin={true} />
      </div >
    </div >
  );
}
export default AdminProductList
