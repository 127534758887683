
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiMinusCircle, BiPlusCircle, BiTrashAlt } from "react-icons/bi";
import './input-quantity.css';
const InputQuantity = ({ quantity, disabled = true, onUpdate, size = '', min = 1 }) => {
  const { t } = useTranslation();
  return (
    <div className={`input-quantity ${size}`}>
      <div className='d-inline-flex flex-row justify-content-center align-items-center'>
        {disabled == false && <span className='pointer me-2' onClick={e => onUpdate(Math.max(min, quantity - 1))}><BiMinusCircle /></span>}
        <input type="number" value={quantity} min={min} onChange={e => onUpdate(Math.max(min, e.target.value))} />
        {disabled == false && <span className='pointer  ms-2' onClick={e => onUpdate(quantity + 1)} ><BiPlusCircle /></span>}
      </div>
    </div >
  )
}
export default InputQuantity;