import React, { useState, useEffect, useRef } from 'react';
import { IoOpenOutline } from "react-icons/io5";
import { formatPrice } from './../../utils';
import ProdCodeStock from './ProdCodeStock';
import ProdType from './ProdType';
import './products-list-item.css';
const ProductListItem = ({ product, isSelected, onSelect, isAdmin }) => {
  {
    return (
      <tr className={`products-list-item ${isSelected ? 'selected' : ''}`} onClick={e => onSelect(product)}>
        {product.prodCatalogue.toLowerCase() != 'kit' &&
          <td className='prodType text-center  pt-2'>
            <ProdType type={product.prodType} />
          </td>
        }
        <td className='ref text-center'>
          {product.prodRef}<br />
          {false &&
            <small>{product.prodCatalogue.toUpperCase()}</small>
          }
        </td>
        {product.prodCatalogue.toLowerCase() != 'kit' ?
          <>
            <td className='constructor'>
              {product.prodMarque}<br />
              <small>{product.prodModele}</small>
            </td>
            <td className='nowrap years text-center'>{product.prodAnnee}
              {/* <br /> {product.availableYears} */}
            </td>
          </>
          :
          <>
            <td className='memo'>
              {product.prodMemo}
            </td>
          </>
        }
        <td className='nowrap type text-center'>{product.prodType}</td>
        <td className='nowrap price text-center'>{formatPrice(product.prodPrix)}</td>
        <td className='nowrap stock text-center pt-2'>
          <ProdCodeStock codeStock={product.prodCodeStock} />
        </td>
        <td className='text-center open text-green big'>
          <IoOpenOutline />
        </td>
      </tr>)
  }
}
export default ProductListItem;
