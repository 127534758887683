import React from 'react';
import { useTranslation } from 'react-i18next';
import './product-code-stock.css';
const ProdCodeStock = ({ codeStock, label = false }) => {
  const { t } = useTranslation();
  return (
    <span className={`product-code-stock stock-${codeStock}`} >
      <a className='d-inline-block' title={t(`stock-${codeStock}`)}>
      </a>
      {label &&
        <label>
          {t(`stock-${codeStock}`)}
        </label>
      }

    </span >
  )
}
export default ProdCodeStock;

