import { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'; // Importe useSelector
import Swal from 'sweetalert2'
import { Link } from 'react-router-dom';

import { formatPrice, download } from './../../../utils';
import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';
import ProdCodeStock from './../ProdCodeStock';
import AdminProductFeatures from './AdminProductFeatures';
import Modal from 'react-bootstrap/Modal';
import API from '../../../api'
import './../../../css/modals.css';
import './../../../css/forms.css';
import './../product-modal.css';
import 'sweetalert2/dist/sweetalert2.min.css';
import { IoCaretBackSharp } from "react-icons/io5";
import { CiViewList } from "react-icons/ci";

const AdminProductModal = ({ product, onClose, canOrderProduct = true }) => {

  const [modalProduct, setModalProduct] = useState({});
  const [formStatus, setFormStatus] = useState('');
  const [activeTab, setActiveTab] = useState('features');
  useEffect(() => {
    get_product();
  }, [product]);
  const get_product = async () => {

    try {

      const response = await API.get(`/admin/product/${product.prodId}`);
      setModalProduct({ ...response.data });
    } catch (e) {
    }
  }
  const handleProductChange = (tab, payload) => {


    switch (tab) {
      case 'specialPrices':
        setModalProduct({ ...modalProduct, specialPrices: payload });
        break;
      default:
        setModalProduct({ ...modalProduct, ...payload })
    }

  }
  const handleSave = (event) => {
    // setModalProduct({ ...modalProduct, [event.target.name]: event.target.value });
  }



  return (
    <Modal show={true} onHide={onClose} className="product-modal" keyboard={true} >
      <Modal.Header closeButton>
        <div className='row flex-grow-1' >
          <div className='col-sm-4'>
            <Modal.Title>Réf : {modalProduct.prodRef} </Modal.Title>
          </div>
          <div className='col-sm-8 text-end pe-4'>
            <a onClick={e => download(`/product/datasheet/${modalProduct.prodId}`)} className='btn btn-light btn-datasheet'>
              <CiViewList size="30" />
              Fiche technique
            </a>

          </div>
        </div>

      </Modal.Header>
      <Modal.Body className="">


        <ul className='nav nav-tabs'>
          <li className='nav-item'><a className={`nav-link ${activeTab === 'features' ? 'active' : ''}`} onClick={e => setActiveTab('features')} >Caractéristiques <span className='required'></span></a></li>
          <li className='nav-item'><a className={`nav-link ${activeTab === 'admin' ? 'active' : ''}`} onClick={e => setActiveTab('admin')}>Admnistration <span className='required'></span></a></li>
          <li className='nav-item'><a className={`nav-link ${activeTab === 'specialPrices' ? 'active' : ''}`} onClick={e => setActiveTab('specialPrices')}>Prix spéciaux</a></li>

          <li className='nav-item ms-auto'>
            <button className='btn btn-green' onClick={handleSave} disabled={false} >
              {formStatus == 'saving' && <span class="spinner-border spinner-border-sm me-2" aria-hidden="true"></span>}
              Sauvegader
            </button>
          </li>
        </ul>
        <div className=' tab-contents'>
          {activeTab === 'features' && <AdminProductFeatures product={modalProduct} onChange={prod => handleProductChange('features', prod)} />}
          {/* activeTab === 'admin' && <AdminCustomerAdmin customer={editedCustomer} onChange={cust => handleCustomerChange('admin', cust)} /> */}
          {/* activeTab === 'specialPrices' && <AdminCustomerSpecialPrices userId={customer.userId} onChange={specialPrices => handleCustomerChange('specialPrices', specialPrices)} /> */}
          {/* activeTab === 'password' && <AdminCustomerPassword onChange={password => handleCustomerChange('password', password)} defaultValue={editedCustomer.password} /> */}
        </div>




        {true && <pre>{JSON.stringify(modalProduct, null, 2)}</pre>}
      </Modal.Body>
      <Modal.Footer>
      </Modal.Footer>
    </Modal>
  )
}
export default AdminProductModal;
