// src/components/homes/HomeDefault.js
import { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux'; // Importe useSelector
import { debug } from '../../utils'
import UserSignInModal from '../user/UserSignInModal';


import Modal from 'react-bootstrap/Modal';
import { FaBeer } from 'react-icons/fa';
import API from '../../api'
import './homes.css';
const HomeDefault = () => {
  const user = useSelector((state) => state.user);
  const [showModal, setShowModal] = useState(false);
  const options = {}
  const onTabSwitch = () => { }
  useEffect(() => {
  }, [user])
  return (
    <>
      {debug(user, false)}
      {!user.isSignedIn &&
        <div className='container  py-5'>
          <h1>
            Bienvenue dans l'extranet d'AMD JAEGER !
          </h1>
          <div className="text-center py-5">
            <button className="btn btn-green btn-lg" onClick={e => setShowModal(true)}>Me connecter</button>
            <UserSignInModal options={options} show={showModal} />
          </div>
        </div>
      }
      {user.isSignedIn &&
        <Navigate to='/home' replace />
      }
    </>
  )
};
export default HomeDefault;
