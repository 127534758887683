import React, { useEffect, useState, useMemo } from 'react';
import API from './../../../api';
import { useTranslation } from 'react-i18next';
import { debug, formatDate, formatPrice, download } from '../../../utils';
import './admin-orders-list.css'; // Ajoute ton CSS pour le spinner ici
import 'bootstrap/dist/css/bootstrap.min.css'; // Assure-toi que Bootstrap est bien importé
import { CiViewList } from "react-icons/ci";
function AdminOrdersList() {
  const { t } = useTranslation();
  const [year, setYear] = useState('2024'); // new Date().getFullYear()
  const [yearOrders, setYearOrders] = useState([]);
  const [loadedCount, setLoadedCount] = useState(0);
  const [loadHash, setLoadHash] = useState({}); // Stocker les identifiants de commandes chargées
  const [loading, setLoading] = useState(true); // Suivre l'état de chargement
  const [filters, setFilters] = useState({
    orderRef: '',
    client: '',
    prodRef: '',
    delivery: ''
  });
  const loadCount = 10000;
  const currentYear = new Date().getFullYear();
  const years = Array.from(new Array(currentYear - 2016 + 1), (val, index) => 2016 + index).reverse();

  useEffect(() => {
    setLoadedCount(0);  // Réinitialiser le compteur
    setYearOrders([]);   // Réinitialiser les commandes
    setLoadHash({});     // Réinitialiser le hash des commandes chargées
    setLoading(true);    // Activer le chargement
    document.body.style.cursor = 'wait'; // Changer le curseur en "loading"
    getOrders(0);        // Commencer le chargement des commandes avec un compteur à 0
  }, [year]);

  const getOrders = async (currentLoadedCount) => {
    try {
      const response = await API.get(`/admin/orders?y=${year}&l=${currentLoadedCount},${loadCount}`);
      const newOrders = response.data;
      const filteredNewOrders = newOrders.filter(order => !loadHash[order.orderId]);
      const updatedLoadHash = { ...loadHash };
      filteredNewOrders.forEach(order => {
        updatedLoadHash[order.orderId] = true;
      });
      setLoadHash(updatedLoadHash);
      setYearOrders(prevOrders => [...prevOrders, ...filteredNewOrders]);
      const newLoadedCount = currentLoadedCount + filteredNewOrders.length;
      setLoadedCount(newLoadedCount);

      // Vérifier si le chargement est terminé
      // if (newOrders.length < loadCount) {
      setLoading(false); // Arrêter le chargement
      document.body.style.cursor = 'default'; // Remettre le curseur par défaut
      // }
    } catch (error) {
      console.log(error);
      setLoading(false); // Arrêter le chargement en cas d'erreur
      document.body.style.cursor = 'default'; // Remettre le curseur par défaut
    }
  };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters(prevFilters => ({ ...prevFilters, [name]: value }));
  };

  const filteredOrders = useMemo(() => {
    return yearOrders.filter(order => {
      const preComputedClient = `${order.customer.userCompName} ${order.customer.userName}`.toLowerCase();
      const preComputedDelivery = order.delivery ? `${order.delivery.daCompName} ${order.delivery.daCity}`.toLowerCase() : '';
      const numberMatches = order.orderRef.startsWith(filters.orderRef.toLowerCase());
      const clientMatches = preComputedClient.includes(filters.client.toLowerCase());
      const prodRefMatches = order.items.some(item => item.itProdRef.includes(filters.prodRef));
      const deliveryMatches = preComputedDelivery.includes(filters.delivery.toLowerCase());
      return numberMatches && clientMatches && prodRefMatches && deliveryMatches;
    });
  }, [yearOrders, filters]);

  return (
    <div id="AdminOrdersList">
      <div className="container">
        {debug(yearOrders[0], false)}
        {debug(yearOrders.length, false)}
        <h1>{t('Orders list')}</h1>

        {/* Spinner ou indicateur de chargement */}
        {loading ?
          <div className="loading-indicator text-center my-3">
            <div className="spinner-border" role="status">
              <span className="sr-only"></span>
            </div>
            <p>{t('Chargement en cours...')}</p>
          </div>
          :
          <div>
            <div className="row">
              <div className="col-md-3">
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <label className="input-group-text" htmlFor="yearSelect" style={{ borderRadius: "0.375rem", background: "var(--green)", color: "#fff" }}>
                      {t('Année')}
                    </label>
                  </div>

                  <select
                    id="yearSelect"
                    className="form-control"
                    value={year}
                    onChange={(e) => setYear(e.target.value)}
                  >
                    {years.map((yearOption) => (
                      <option key={yearOption} value={yearOption}>
                        {yearOption}
                      </option>
                    ))}

                  </select>

                </div>

              </div>
            </div>

            <table className="table table-sm table-striped">
              <thead>
                <tr>
                  <th>{t('Date')}</th>
                  <th>
                    <input
                      type="text"
                      className="form-control form-control-sm text-center"
                      placeholder={t('orderRef')}
                      name="orderRef"
                      value={filters.orderRef}
                      onChange={handleFilterChange}
                    />
                  </th>
                  <th>
                    <input
                      type="text"
                      className="form-control form-control-sm text-center"
                      placeholder={t('customer')}
                      name="client"
                      value={filters.client}
                      onChange={handleFilterChange}
                    />
                  </th>
                  <th>
                    <input
                      type="text"
                      className="form-control form-control-sm text-center"
                      placeholder={t('prodRefs')}
                      name="prodRef"
                      value={filters.prodRef}
                      onChange={handleFilterChange}
                    />
                  </th>
                  <th>
                    <input
                      type="text"
                      className="form-control form-control-sm text-center"
                      placeholder={t('Delivery')}
                      name="delivery"
                      value={filters.delivery}
                      onChange={handleFilterChange}
                    />
                  </th>
                  <th>{t('orderTotalPriceIT')}</th>
                  <th style={{ width: '80px' }}></th>
                </tr>
              </thead>
              <tbody>

                {filteredOrders.map((order, index) => (
                  <tr key={order.orderId}>
                    <td className='text-center'>{formatDate(order.orderDate)}</td>
                    <td className='text-center'>{order.orderRef}<br />{order.orderInternal}</td>
                    <td className='text-center'>
                      {order.customer.userCompName}<br />
                      {order.customer.userName} {order.customer.userFirstname}<br />
                      {order.customer.userPhone}<br />
                      <a href={`mailto:${order.customer.userEmail}`}>{order.customer.userEmail}</a>
                    </td>
                    <td className="text-center">{order.items.map((item, key) => (<span key={key}>
                      {item.itProdRef} X {item.itQuantity}<br />
                    </span>))}</td>
                    <td className="text-center">
                      {order.delivery && <>
                        {order.delivery.daCompName != '' && <>{order.delivery.daCompName}<br /></>}
                        {order.delivery.daName}  {order.delivery.daFirstname}<br />
                        {order.delivery.daAddress}<br />
                        {order.delivery.daPostCode} {order.delivery.daCity}<br />
                        {order.delivery.daPhone1}<br />
                        {order.delivery.daEmail != '' &&
                          <a href={`mailto:${order.delivery.daEmail}`}>{order.delivery.daEmail}</a>
                        }</>
                      }
                    </td>
                    <td className="text-center">{formatPrice(order.orderTotalPriceIT)}</td>
                    <td className="text-center">
                      <a onClick={e => download(`/order/pdf/${order.orderId}`, t)}>
                        <CiViewList size="24" />
                      </a>
                    </td>
                  </tr>
                ))}

              </tbody>
            </table>
          </div>
        }

        {/* Filtre par année */}

      </div>
    </div>
  );
}

export default AdminOrdersList;
