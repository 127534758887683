// ProductInfo.js
import { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import API from './../../api';
import OrderItem from './../orders/OrderItem';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import './product-features.css';
function ProductInfo({ prodId }) {
  console.log(process.env.PUBLIC_URL);
  const [config, setConfig] = useState(null)
  const [product, setProduct] = useState(null)
  const { t } = useTranslation();
  useEffect(() => {
    if (prodId) {
      API.get(`/product/${prodId}`).then(response => {
        setProduct(response.data);
      });
    }
    API.get(`/products/config`).then(response => {
      setConfig(response.data);
    });
  }, [prodId]);
  const getLegendText = (key) => {
    const legends = t('legends', { returnObjects: true }); // Assure-toi que returnObjects est bien à true pour retourner l'objet
    const legend = legends.find(legend => legend.key === key);
    return legend ? legend.def : null;
  }
  return (
    <div className="product-features">
      {product && config &&
        <div className='container text-start'>
          <h2 className='text-center mb-3'>Caractéristiques :</h2>
          <table className='table table-striped table-sm'>
            <tbody>
              {config.props[product.prodCatalogue.toLowerCase()].map((prop, idx) => (
                <tr key="idx">
                  <td>{t(prop)}</td>
                  <td>{product[prop]}</td>
                  <td>{getLegendText(product[prop])}</td>
                </tr>
              ))}
            </tbody>
          </table>
          {false && <pre>{JSON.stringify(product, null, 2)}</pre>}
        </div>
      }
    </div >
  );
}
export default ProductInfo;
