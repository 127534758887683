// DeliveryListItem.js
import { useState, useEffect } from 'react';
import API from './../../api';
import Swal from 'sweetalert2'
import { useParams } from 'react-router-dom';
import './deliveries-list-item.css';
import 'sweetalert2/dist/sweetalert2.min.css';
import { AiOutlinePhone, AiOutlineMail, AiOutlineDelete, AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import { MdOutlineCheckBox, MdOutlineCheckBoxOutlineBlank } from "react-icons/md";
function DeliveriesListItem({ item, selected, onClick }) {
  const [collapsed, setCollapsed] = useState(true);
  return (
    <div className={`deliveries-list-item mb-1 pb-1 pt-1 pb-1 ps-2 pe-2 ${(selected ? 'selected' : '')} ${(collapsed ? 'collapsed' : '')} `} >
      <div className='d-flex flex-row'>
        <div className="me-2">
          <span className='d-inline-block pointer'
            onClick={e => onClick('select', item)}
          >{selected ? <MdOutlineCheckBox /> : <MdOutlineCheckBoxOutlineBlank />}
          </span>
        </div>
        <div className='flex-fill pointer' onClick={e => onClick('select', item)}>
          {item.daCompName.trim() != '' ? <>
            <h4 className='mb-0'>{item.daCompName}</h4>
            <h5 className='mb-1'>{item.daName} {item.daFirstname}</h5>
          </> :
            <>
              <h4 className='mb-1'>{item.daName} {item.daFirstname}</h4>
            </>
          }
          <p className='mb-0'>{item.daAddress}</p>
          <p className='mb-1'>{item.daPostCode} {item.daCity} - {item.daCountry}</p>
          <p className='mb-0'>
            {item.daPhone1.trim() != '' && <span className='d-inline-block me-3'>
              <AiOutlinePhone className='me-1' />
              {item.daPhone1}
            </span>}
            {item.daPhone2.trim() != '' && <span className='d-inline-block me-3'>
              <AiOutlinePhone className='me-1' />
              {item.daPhone2}
            </span>}
            {item.daEmail.trim() != '' && <span className='d-inline-block me-3'>
              <AiOutlineMail className='me-1' />
              {item.daEmail}
            </span>}
          </p>
        </div>
        <div className='actions'>

          <span className='d-inline-block pointer ms-2'
            onClick={e => onClick('delete', item)}
          ><AiOutlineDelete /></span>
          <span className='d-inline-block pointer ms-2'
            onClick={e => setCollapsed(!collapsed)}
          >
            {collapsed && <AiFillCaretDown />}
            {!collapsed && <AiFillCaretUp />}
          </span>
        </div>

      </div>
      {false && <pre>{JSON.stringify(item, null, 2)}</pre>}
    </div >
  );
}
export default DeliveriesListItem;
