import { configureStore } from '@reduxjs/toolkit';
import userReducer from './userSlice';
import cartReducer from './cartSlice';
import configReducer from './configSlice';
const store = configureStore({
  reducer: {
    user: userReducer,
    cart: cartReducer,
    config: configReducer,
  },
});
export default store;
