import { useEffect } from 'react';


import './defaultLayout.css';
const DefaultLayout = ({ children }) => {
  useEffect(() => {
    document.body.classList.add('default-layout');
    return () => {
      document.body.classList.remove('default-layout');
    };
  }, []);
  return (
    <div>
      <div className="default-layout">
        {children}
      </div>
    </div>
  );
};

export default DefaultLayout;