// Faq.js
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDate } from './../../utils'
import { MdOpenInNew } from "react-icons/md";
import API from './../../api';
import { Link, useNavigate } from "react-router-dom";
import InputAutocomplete from '../helpers/InputAutocomplete/InputAutocomplete';
// import Truncate from 'react-truncate-html';
import { useDispatch, useSelector } from 'react-redux';
import './faq.css';
// const striptags = require('striptags');
import striptags from 'striptags';

function Faq() {
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState(null);
  const dispatch = useDispatch();
  const [threads, setThreads] = useState([]);
  useEffect(() => {
    try {
      API.get('/faq/threads').then(response => {
        setThreads(response.data);
        console.log(response.data);
      });
    }
    catch (e) {
    }
    try {
      API.get('/faq/availableRefs').then(response => {
        setNewThreadAvailableRefs(response.data);

      });
    }
    catch (e) {
    }
  }, [])
  const { t } = useTranslation();
  const user = useSelector((state) => state.user);
  /* New Thread */
  const [newThreadRef, setNewThreadRef] = useState('');
  const [newThreadAvailableRefs, setNewThreadAvailableRefs] = useState([]);
  const handleNewThreadRefSelect = (name, value) => {
    setNewThreadRef(value);
    navigate(`/faq/${value}`)
  }
  return (
    <div id="faq">
      <div className='container'>
        <h1>{t('Support forum')}</h1>
        <table className='table table-striped'>
          <thead>
            <tr>
              <th>{t('Reference')}</th>
              <th>{t('Last posts')}</th>
              <th>{t('Author')} <br /><small>{t('Date')}</small></th>
              <th>{t('Posts_Count')}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {threads.map(thread => (
              <tr key={thread[0].postRef}>
                <td>{thread[0].postRef}</td>

                <td>{striptags(thread[0].postText.substring(0, 120))}...</td>
                <td className='text-center'>
                  {thread[0].postAuthor}<br />
                  <small>{formatDate(thread[0].postCreatedAt)}</small>
                </td>
                <td className='text-center'>{thread.length}</td>
                <td>
                  <Link to={`/faq/${thread[0].postRef}`}><MdOpenInNew size="18" /></Link>
                </td>
              </tr>
            ))};

            {/*
            {Object.keys(threads).map(ref => (
              <tr key={ref}>
                <td>{ref}</td>
             
                <td>{striptags(threads[ref][0].postText.substring(0, 120))}...</td>
                <td className='text-center'>
                  {threads[ref][0].postAuthor}<br />
                  <small>{formatDate(threads[ref][0].postCreatedAt)}</small>
                </td>
                <td className='text-center'>{threads[ref].length}</td>
                <td>
                  <Link to={`/faq/${ref}`}><MdOpenInNew size="18" /></Link>
                </td>
              </tr>
            ))}
              */}
          </tbody>
        </table>
        <div className='text-center py-5 add-thread'>
          <form className='py-3 px-3 rounded border'>
            <h2 className='mb-3'>Poser une question ou ajouter une contribution</h2>
            <div className='input-group text-start'>
              <InputAutocomplete
                suggestionsSrc={newThreadAvailableRefs}
                name='newThreadRef'
                debug={false}
                value={newThreadRef}
                onChange={(name, value) => setNewThreadRef(value)}
                onSuggestionSelect={handleNewThreadRefSelect}
                placeholder='Sélectionner une référence'
              />

            </div>
            <p>{newThreadRef}</p>
          </form>
        </div>

      </div >
    </div >
  );
}
export default Faq;
/*
   {<td dangerouslySetInnerHTML={{ __html: striptags(threads[ref][threads[ref].length - 1].postText.substring(0, 120)) }}>...</td>}
*/