// cartSlice.js
import { createSlice } from '@reduxjs/toolkit';
import API from './../api';

const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    items: [],
    isLoading: false,
    loaded: false,
  },
  reducers: {
    setCart: (state, action) => {
      Object.assign(state, action.payload);
    },
    setCartItems: (state, action) => {
      Object.assign(state, action.payload);
    },
    setIsLoading: (state, bool) => {
      state.isLoading = bool.payload; // Marque le chargement comme en cours
    }

  },
});
export const { setCart, setCartItems, setIsLoading } = cartSlice.actions; // Exporte setCart
export default cartSlice.reducer;
export const loadCart = () => async (dispatch, getState) => {
  try {
    const { isLoading } = getState().cart;
    if (!isLoading) {
      dispatch(setIsLoading(true));
      const response = await API.get('/cart');
      dispatch(setCart({ ...response.data, loaded: true }));
    }
  } catch (error) {
    console.error('Erreur lors du chargement du panier :', error);
  } finally {
    dispatch(setIsLoading(false));
  }
};
export const AddCartItem = (payload) => async (dispatch) => {
  try {
    const response = await API.post('/cart/item', payload);
    dispatch(setCartItems(response.data));
  } catch (error) {
    throw ('Erreur lors de la mise à jour des éléments du panier:', error);
    return error;
  }
};
export const updateCartItems = (payload) => async (dispatch) => {
  try {
    const response = await API.post('/cart/items', payload);
    const responseData = response.data;
    dispatch(setCartItems(responseData));
  } catch (error) {
    console.error('Erreur lors de la mise à jour des éléments du panier:', error);
  }
};
