// PostEdit.js
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import API from './../../api';
import { useDispatch, useSelector } from 'react-redux';
import './post-edit.css';
import './../../css/forms.css';
import TextEditor from './../helpers/TextEditor/TextEditor';
import { fetchUser } from './../../redux/userSlice'
import { BiTrashAlt, BiRotateRight } from "react-icons/bi";
// import Uploader from './../helpers/Uploader/Uploader';
import Picture from './../helpers/Picture/Picture';
function PostEdit({ post, onSaved }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [postCopy, setPostCopy] = useState({ postRef: '', postText: '', postPictures: [] }); // 
  const [editorContent, setEditorContent] = useState('');
  const [formSumitted, setFormSumitted] = useState(false);
  const [error, setError] = useState('');
  useEffect(() => { dispatch(fetchUser()) }, [])
  useEffect(() => {
    if (post && post.postRef) {
      setPostCopy({
        postStatus: 'unvalidated',
        postPictures: [],
        ...post
      });
      setEditorContent(post.postText);
    }

  }, [post]);
  const handleUploadSuccess = (upload) => {
    try {
      setPostCopy((postCopy) => ({ ...postCopy, postPictures: [...postCopy.postPictures, upload.path] }));
    } catch (e) {
      console.log(e.message);
    }
  }
  const ckeditorConfig = {
    toolbar: [
      { name: 'basicstyles', items: ['Bold', 'Italic'] },
      { name: 'links', items: ['Link'] },
      { name: 'document', items: ['Source'] },
      { name: 'cleanup', items: ['RemoveFormat'] },
    ],
    forcePasteAsPlainText: true,
  };
  const handleTextEditorChange = (content) => {
    setPostCopy({ ...postCopy, postText: content });
  }
  const uploaderInputProps = {
    accept: {
      'image/jpg': ['.jpeg', '.jpg',],
      // 'application/pdf': ['.pdf'],
    },
    multiple: true,
    maxSize: 100
  };
  const handleSave = async () => {
    false && setFormSumitted(true);
    setError('');
    const data = { ...postCopy, postRef: post.postRef }; // postCopy.postRef est écrasé quelque part !
    try {
      let response = null;
      if (PostEdit.postId) {
        response = await API.put(`/faq/post/${post.postId}`, data)
      } else {
        response = await API.post(`/faq/post`, data)
      }
      setPostCopy({ ...response.data });
      onSaved(response.data);
    } catch (error) {
      setFormSumitted(false);
      setError(error)
    }
  }
  const handleRemoveAttachment = (attachment) => {
    const attachments = postCopy.postPictures.filter(att => att != attachment);
    setPostCopy({ ...postCopy, postPictures: attachments });
  }
  const handleRotateAttachment = async (attachment) => {
    const parts = attachment.split('?');
    const response = await API.put('/file/rotate', { path: parts[0], deg: -90 }); // rotate right
    const attachments = postCopy.postPictures.map(att => att != attachment ? att : attachment + '?r=' + new Date().getTime())
    setPostCopy({ ...postCopy, postPictures: attachments });
  }
  return (
    <div className="post-edit">
      <div className='container'>
        <div className='form-group'>
          <TextEditor data={editorContent} onChange={handleTextEditorChange} config={ckeditorConfig} />
          {postCopy.postText.length < 20 && <small className="text-red">{postCopy.postText.length} / 20 caractères minimum</small>}
        </div>
        <div className='row'>
          <div className='col-sm-8'>
            <div className='form-group'>
              <label className='form-group-label'><strong>Fichiers joints :</strong></label>
              <ul className='attachments p-0 m-0 d-flex flex-wrap flex-row bd-highlight'>
                {postCopy.postPictures.map((attachment, idx) => (
                  <li className=' d-flex flex-column  py-1' key={idx}>
                    <div className=' flex-grow-1 picture'>
                      <Picture src={attachment} size='thumb' className='' />
                    </div>
                    <div>
                      <span onClick={e => handleRemoveAttachment(attachment)} className='ps-1 pe-2 pointer'>
                        <BiTrashAlt size={18} />
                      </span>
                      <span onClick={e => handleRotateAttachment(attachment)} className='ps-1 pe-2 pointer'>
                        <BiRotateRight size={18} />
                      </span>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className='col-sm-4'>
            <div className='form-group'>
              {/*
              <Uploader
                inputProps={uploaderInputProps}
                onUploadStart={e => { }}
                onUploadSuccess={handleUploadSuccess}
                onUploadFailure={e => { }}
                size='large'
              />
              */}
            </div>
          </div>
        </div>
        {user?.roles?.faq === 'admin' && <div className='form-group form-inline'>
          <label className='form-group-label'><strong>Statut du post : </strong></label>
          <select
            className='form-control form-control-sm  w-md-50'
            onChange={e => setPostCopy({ ...postCopy, postStatus: e.target.value })}
            value={postCopy.postStatus}>
            <option value="unvalidated">&raquo; Sélectionner</option>
            <option value="validated">Validé</option>
            <option value="draft">Brouillon</option>
          </select>
          {postCopy.postStatus === 'unvalidated' && <small className="text-red">Précisez le statut</small>}
        </div>}
        <div className='form-group text-left '>
          {error != '' && <div className='alert alert-danger small p-2' >{error}</div>}
          <button
            className='btn btn-green'
            onClick={handleSave}
            disabled={formSumitted}
          >Sauvegarder</button>
        </div>
        {false && <pre>{JSON.stringify(postCopy, null, 2)}</pre>}
      </div >
    </div >
  );
}
export default PostEdit;
