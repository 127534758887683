import React from 'react';
import { useTranslation } from 'react-i18next';
import './product-type.css';
const ProdType = ({ type }) => {
  const { t } = useTranslation();
  return (
    <span className={`product-type d-inline type-${type !== '' ? type : 13}`} >
      <a className='d-inline-block' title={t(`product-type-${type !== '' ? type : 13}`)}>
      </a>
    </span>
  )
}
export default ProdType;

