// Header.js
import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Header_User from './../user/Header_User';
import Header_Cart from './../cart/Header_Cart';
import { Link } from 'react-router-dom';
import './header.css';
const Header = React.memo(() => {
  const user = useSelector((state) => state.user);
  const { t } = useTranslation();
  return (
    <header className="App-header" id="header">
      <div id="header-main">
        <div className='row'>
          <div className='col' id='header-main-left'>
            {
              user.isSignedIn &&
              <Link className='logo' to="/">
                <img src='/img/logo.png' alt='amd jaeger' />
              </Link>
            }
          </div>
          {user.isSignedIn ?
            <div className='col' id='header-main-center'>
              <Link to="/">Accueil</Link>
              <Link to="/catalog">Catalogue</Link>
              <Link to="/orders">Commandes</Link>
              <Link to="/faq">{t('Forum/Faq')}</Link>
            </div> :
            <div className="text-center">
              <span className="logo">
                <img src='/img/logo.png' alt='amd jaeger' />
              </span>
            </div>
          }
          <div className='col' id='header-main-right'>
            {user.isSignedIn && <>
              <Header_User />
              <Header_Cart />
            </>}
          </div>
        </div>
      </div>
    </header>
  );
});

export default React.memo(Header);
