// OrderSummary.js
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { formatPrice, debug } from './../../utils';
import { Link } from 'react-router-dom';
import './order-summary.css';
import API from './../../api';
import { useDispatch, useSelector } from 'react-redux';
import { loadCart } from '../../redux/cartSlice';
const OrderSummary = ({ order, onChange, onSaved }) => {
  const cart = useSelector((state) => state.cart);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState('');
  const handleChange = (event) => {
    onChange({ [event.target.name]: event.target.value });
  }
  const handleSave = async () => {
    try {
      setSubmitted(true);
      const response = await API.post('/order', order);
      onSaved(response.data)
    } catch (error) {
      setError(error.response.data.error.message);
      setSubmitted(false);
      console.log(error);
    }
  }
  return (
    <div id="order-summary" className='' >



      {!order.orderStatus.startsWith('val') > 0 ? <>
        <h2 className="mb-3 text-start">{t('Summary')} :</h2>
        <div className='row mb-5 small'>
          <div className='col-md-3 col-sm-6'>
            <p className='mb-1'><strong>Articles :</strong></p>
            {order.orderId == 'current' ? <>
              <table>
                <tbody>
                  <tr>
                    <td className="pe-3">Nombre</td>
                    <td className='ps-3 text-end'>{cart.items.length}</td>
                  </tr>
                  <tr>
                    <td className="pe-3">{t("totalPriceET")} </td>
                    <td className='ps-3 text-end'>{formatPrice(cart.totalPriceET)} EUR</td>
                  </tr>
                  <tr>
                    <td className="pe-3">{t("orderVATAmount")} </td>
                    <td className='ps-3 text-end'>{formatPrice(cart.orderVATAmount)} EUR</td>
                  </tr>
                  <tr>
                    <td className="pe-3">{t("totalPriceIT")} </td>
                    <td className="ps-3 text-end">{formatPrice(cart.totalPriceIT)} EUR</td>
                  </tr>
                </tbody>
              </table>
            </>
              :
              <>
              </>}
          </div>
          <div className='col-md-3 col-sm-6'>
            <p className='mb-1'><strong>Adresse de livraion :</strong></p>
            {order.delivery.daCompName != '' && <>{order.delivery.daCompName}<br /></>}
            {order.delivery.daName} {order.delivery.daFirstname}<br />
            {order.delivery.daAddress}<br />
            {order.delivery.daPostCode} {order.delivery.daCity}<br />
            {order.delivery.daCountry}<br />
            {order.delivery.daEmail}<br />
            {order.delivery.daPhone1} <br />
            {order.delivery.daPhone2}
          </div>
        </div>
        <div className='row mb-5 small'>
          <div className='col-md-6'>
            <div className="form-group orderInternalRef">
              <p className='mb-1'><strong>Vous pouvez préciser une réfénce interne pour cette commande : </strong></p>
              <input
                type="text"
                value={order.orderInternalRef}
                name="orderInternalRef"
                onChange={handleChange}
                className="form-control orderInternalRef" />
            </div>
          </div>
        </div>
        {error != '' &&
          <div className='row mb-5 small'>
            <div className='col-md-6'>
              <div className='alert alert-danger  mb-3'>
                Une erreur inattendue est survenue. ({t(error)})
              </div>
            </div>
          </div>
        }
        <div className="form-group mb-3 submit">
          <button
            type="button"
            className="btn btn-green btn-lg"
            disabled={order.orderDelivryId == ''}
            onClick={handleSave}>
            Valider et envoyer ma commande</button>
        </div>
      </> : <div className='text-center'>
        <h2 className='mb-5'>Votre commande est validée.</h2>
        <p><Link to="/">Accueil</Link></p>
      </div>
      }
    </div >
  );
};
export default OrderSummary;
/*
|| submitted
*/
