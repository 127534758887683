//  i18n.js
/*
import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import translationFR from './locales/fr.json';
import userFR from './components/user/fr.json';
import ordersFR from './components/orders/fr.json';
import cartFR from './components/cart/fr.json';
i18n
  .use(initReactI18next)
  .init({
    resources: {
      fr: {
        common: translationFR,
        user: userFR,
        orders: ordersFR,
        cart: cartFR,
      }
    },

    defaultNS: "common",
    lng: 'fr',
    fallbackLng: 'fr',
    interpolation: {
      escapeValue: false
    }
  });
export default i18n;
*/
import API from './api'
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
i18n
  .use(HttpApi) // permet de charger les traductions via une API
  .use(LanguageDetector) // détecte la langue du navigateur
  .use(initReactI18next) // passe i18n down aux composants react

  .init({
    supportedLngs: ['fr'], // les langues que tu supportes
    fallbackLng: false, // langue par défaut
    debug: false,
    lng: 'fr',
    detection: {
      order: ['queryString', 'cookie', 'localStorage', 'path', 'subdomain'],
      caches: ['cookie'],
    },
    backend: {
      loadPath: API.getUrl() + '/config/languages/{{lng}}', // chemin pour charger les traductions pour chaque langue
    },
  });

export default i18n;
