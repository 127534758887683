import React, { useEffect, useState } from 'react';
import API from '../../../api';
import { debug } from '../../../utils';
const DialCodes = ({ number = '', onUpdate, disabled = false }) => {
  const [dialCodes, setDialCodes] = useState([]);
  const [phoneParts, setPhoneParts] = useState({ dial: '', number: '' });
  useEffect(() => {
    const get_dial_codes = async () => {
      try {
        const response = await API.get('/config/dial_codes');
        setDialCodes(response.data)
      } catch (e) {
        setDialCodes([
          {
            "dial_code": "+33",
            "code": "FR",
          }
        ]);
      }
    }
    get_dial_codes();
  }, []);
  useEffect(() => {
    const parts = number.trim().split(' ', 2);
    if (number != phoneParts.dial + ' ' + phoneParts.number) {

      if (parts.length == 2) {
        setPhoneParts({
          dial: parts[0]?.trim(),
          number: parts[1].replace(/\D/g, "").replace(/^0+/, "")
        });
      } else {
        setPhoneParts({
          dial: "+33",
          number: '',
        });

      }

    }
  }, [number]);
  const handleChange = (event) => {
    const phonePartsCopy = { ...phoneParts }
    let { name, value } = event.target;
    switch (name) {
      case 'dial':
        onUpdate(value + ' ' + phonePartsCopy.number);
        break;
      case 'number':
        break;
    }
    setPhoneParts({ ...phoneParts, [name]: value });
  }
  const handleBlur = (event) => {
    const phonePartsCopy = { ...phoneParts }
    let { name, value } = event.target;
    switch (name) {
      case 'dial':
        break;
      case 'number':
        phonePartsCopy.number = value.replace(/\D/g, "").replace(/^0+/, "");
        break;
    }
    onUpdate(phonePartsCopy.dial + ' ' + phonePartsCopy.number);
    setPhoneParts({ ...phonePartsCopy });
  }
  return (
    <>
      {debug(phoneParts, 0)}
      <div className='input-group input-group-sm'>
        <select
          className='form-control flex-grow-0'
          disabled={disabled}
          name="dial"
          style={{ width: '80px' }}
          value={phoneParts.dial}
          onBlur={handleBlur}
          onChange={handleChange}
        >
          {dialCodes.map(dialCode => (
            <option key={dialCode.code} value={dialCode.dial_code}>{dialCode.dial_code}</option>
          ))
          }
        </select>
        <input
          type="text"
          className="form-control form-control-sm"
          name="number"
          autoComplete="none"
          placeholder=""
          value={phoneParts.number}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={disabled}
        />
      </div>
    </>
  )
}
export default DialCodes;
