import { useState, useEffect, useRef } from 'react';
import API from './../../../api';
import InputAutocomplete from './../../helpers/InputAutocomplete/InputAutocomplete';
import AdminProductFeaturesFaisceau from './AdminProductFeaturesFaisceau';
const AdminProductFeatures = ({ product, onChange }) => {

  const [constructors, setConstructors] = useState([]);
  const suggestMarques = useRef(null);
  useEffect(() => {
    if (product.prodCatalogue) {
      API.get('/admin/products/constructors?prodCatalogue=' + product.prodCatalogue).then(response => {
        setConstructors(response.data)
      })
    }
  }, [product.prodCatalogue]);
  useEffect(() => { console.log(product) }, [product])
  const handleChange = (event) => {
    onChange({ [event.target.name]: event.target.value });
  }
  return (<div id="AdminProductFeatures">
    <div className='row'>
      <div className='col-sm-6  pe-3'>
        <div className='row'>
          <div className='col-sm-6'>
            <div className='form-group'>
              <label className='form-group-label' required >Référence : </label>
              <input type='text' className='form-control form-control-sm' name="prodRef" onChange={handleChange} value={product.prodRef} />
            </div>
          </div>
          <div className='col-sm-6'>
            <div className='form-group'>
              <label className='form-group-label' required >Prix : </label>
              <input type='number' className='form-control form-control-sm' name="prodPrix" onChange={handleChange} value={product.prodPrix} />
            </div>
          </div>
        </div>
        <div className='form-group' style={{ height: '60px' }}>
          <label className='form-group-label required '>Catalogue : </label>
          <div>
            <label className='radio-label me-3'>
              Faisceaux
              <input type='radio' name="prodCatalogue" onChange={handleChange} value="Faisceau" checked={product.prodCatalogue === 'Faisceau'} />
            </label>
            <label className='radio-label me-3'>
              Attelages
              <input type='radio' name="prodCatalogue" onChange={handleChange} value="Attelage" checked={product.prodCatalogue === 'Attelage'} />
            </label>
            <label className='radio-label'>
              Kits
              <input type='radio' name="prodCatalogue" onChange={handleChange} value="Kit" checked={product.prodCatalogue === 'Kit'} />
            </label>
          </div>
        </div>
        <div className='form-group'>
          <label className='form-group-label' required>Constructeur : </label>
          <InputAutocomplete
            inputRef={suggestMarques}
            inputClassName='form-control-sm'
            suggestionsSrc={constructors}
            name='prodMarque'
            value={product.prodMarque}
            onChange={handleChange}
            debug={false}
            onSuggestionSelect={(key, value) => onChange({ [key]: value })} />
        </div>
        <div className='form-group'>
          <label className='form-group-label required ' >Modèle : </label>
          <input type='text' className='form-control form-control-sm' name="prodModele" onChange={handleChange} value={product.prodModele} />
        </div>
        <div className='form-group'>
          <label className='form-group-label  ' >Années : </label>
          <input type='text' className='form-control form-control-sm' name="prodAnnee" onChange={handleChange} value={product.prodAnnee} />
        </div>

      </div>
      <div className='col-sm-6'>
        <div className='row'>
          <div className='col-sm-6'>

            <div className="form-group">

              <label className='form-group-label  ' >Code EAN : </label>
              <input type='text' className='form-control form-control-sm' name="prodCodeEAN" onChange={handleChange} value={product.prodCodeEAN} />

            </div>
          </div>
          <div className='col-sm-6'>
            <div className="form-group">
              <label className='form-group-label required '>Statut : </label>
              <select className="form-control form-control-sm" name="prodStatut" value={product.prodStatut} onChange={onChange}>
                <option value=""></option>
                <option value="disponible">Disponible</option>
                <option value="non disponible">Non disponible</option>
                <option value="supprimé">Produit à supprimer</option>
              </select>
            </div>
          </div>


        </div>
        {product.prodCatalogue === 'Faisceau' && <AdminProductFeaturesFaisceau product={product} onChange={handleChange} />}



      </div>
    </div>
  </div>
  )
}

export default AdminProductFeatures;

