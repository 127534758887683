import axios from 'axios';

import { swalAlert } from './utils';
const api_url = 'https://amd.sys8.animanet.eu/api'; // process.env.REACT_APP_API_URL; //'https://api.if.an1.fr';

const API = axios.create({
  baseURL: api_url,
  timeout: 30000,
  handleError: true
});

API.getUrl = () => {
  return api_url;
}
API.getRequestHeaders = (route) => {

  const headers = {};
  let token = null
  if (!route.startsWith('/admin')) {
    token = localStorage.getItem('token');
  } else {
    token = localStorage.getItem('adminToken');
  }
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  return headers;
}



API.interceptors.request.use(
  (config) => {
    config.headers = { ...config.headers, ...API.getRequestHeaders(config.url) };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

API.interceptors.response.use(
  (response) => {
    return response; // Réponse réussie
  },
  (error) => {


    if (error.response) {
      if (API.handleError && error.response.status === 404) {
        console.warn('Erreur 404: Ressource non trouvée', error.response.config.url);
        const requestedUrl = error.response.config.url; // Récupère l'URL qui a causé l'erreur
        swalAlert("La ressource <br>''" + requestedUrl + "''<br> est introuvable (Erreur 404)."); // Affiche une alerte avec l'URL
        return Promise.resolve({ data: null, status: 404 });
      } else {
        API.handleError = true;
        console.error('Erreur de réponse:', error.response);
        throw error;
        return Promise.resolve({ data: null, status: error.response.status });
      }
    } else {

      console.error('Erreur de réseau ou autre', error.message);
    }


    return Promise.reject(error);
  }
);

export default API;
