import React, { useEffect, useState } from 'react';
import { CKEditor } from 'ckeditor4-react';

import defaultEditorConfig from './defaultEditorConfig';

const TextEditor = ({ data, config = null, onChange }) => {
  const [editorData, setEditorData] = useState(data);
  const [editorInstance, setEditorInstance] = useState(null); // Stocker l'instance de l'éditeur
  useEffect(() => {
    setEditorData(data);
    if (editorInstance) {
      editorInstance.setData(data);
    }
  }, [data, editorInstance]);

  const ckeditorConfig = config ? config : defaultEditorConfig;

  return (
    <>
      <CKEditor
        data={editorData}
        config={ckeditorConfig}
        onChange={(event) => {
          const editorData = event.editor.getData();
          // setEditorData(editorData);
          onChange(editorData);
        }}
        onInstanceReady={(event) => {
          // Stocker l'instance de l'éditeur lorsque l'instance est prête

          setEditorInstance(event.editor);
        }}
      /></>
  );
};

export default TextEditor;
