import React, { useEffect, useState } from 'react';
import API from './../../../api';
const Picture = ({
  src,
  size = '',
  width = '',
  height = '',
  className = ''
}) => {
  const [file, setFile] = useState({
    contents: ''
  });
  useEffect(() => {
    if (src) {

      get_contents();
    }
  }, [src])
  const get_contents = async () => {
    try {
      // const extension = src.split('.').pop().toLowerCase();
      // const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'];
      let url = '';
      // if (!imageExtensions.includes(extension)) {
      //   url = src;
      // } else {
      url = `/utils/file?path=${src}${size !== '' ? '&size=' + size : ''}`
      // }
      const response = await API.get(url);
      setFile(response.data);
    } catch (error) {

    }
  }
  return (
    <>
      {file.contents != '' &&
        <img
          src={`data:${file.mimetype};base64,${file.contents}`}
          alt='' className={`${className}`}
          style={{
            width: width ? `${width}px` : undefined,
            height: height ? `${height}px` : undefined
          }}
        />
      }
    </>
  );
};
export default Picture;

