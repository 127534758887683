// Cart.js
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { formatPrice } from './../../utils';
import { Link } from "react-router-dom";
import OrderItem from './../orders/OrderItem';
import { useDispatch, useSelector } from 'react-redux';
import './cart.css';
import { loadCart, updateCartItems } from '../../redux/cartSlice';
import ProductModal from '../products/ProductModal';
function Cart() {

  const [selectedItem, setSelectedItem] = useState(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const cart = useSelector((state) => state.cart);
  useEffect(() => {
    dispatch(loadCart())
  }, []);
  const handleItemUpdate = (updatedItem) => {
    const updatedItems = cart.items.map(item =>
      item.itId === updatedItem.itId ? updatedItem : item
    );
    dispatch(updateCartItems(updatedItems))
  }
  const handleItemClick = (item) => {
    setSelectedItem(item)
  }
  const handleCloseModal = () => {
    setSelectedItem(null);
  }
  return (
    <div className="cart">
      <div className='container'>
        <h1>{t('Order in progress')}</h1>
        {cart.items.length === 0 &&
          <div className='text-center'>
            <div className=' pt-5 mb-5 text-green'>
              {t('Cart is empty')}
            </div>
            <Link to="/">{t('Add products')}</Link>
          </div>}
        {cart.items.length > 0 &&
          <>
            <table className='table table-striped mb-5 text-center'>
              <thead>
                <tr className="">
                  <th>{t('REF')}</th>
                  <th>{t('Desc')}</th>
                  <th>{t('PU')}</th>
                  <th>{t('Qty')}</th>
                  <th>{t('HT')}</th>
                  <th>{t('TVA')}</th>
                  <th>{t('TTC')}</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {cart.items.map(item => (
                  <OrderItem key={item.itId} item={item} onUpdate={handleItemUpdate} onClick={handleItemClick} />
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={6} className='text-end'>
                    {t('totalPriceET')}
                  </td>
                  <td className='price'>{formatPrice(cart.totalPriceET)}</td>
                  <td colSpan={1} className=''></td>
                </tr>
                <tr>
                  <td colSpan={6} className='text-end'>{t('orderVATAmount')}</td>
                  <td className='price'>{formatPrice(cart.orderVATAmount)}</td>
                  <td colSpan={1} className=''></td>
                </tr>
                <tr>
                  <td colSpan={6} className='text-end'>{t('totalPriceIT')}</td>
                  <td className='price'>{formatPrice(cart.totalPriceIT)}</td>
                  <td colSpan={1} className=''></td>
                </tr>
              </tfoot>
            </table>
            <div className='text-center'>
              <Link to="/catalog" className="btn btn-green ms-5 me-5">Ajouter des articles</Link>
              <Link to="/deliverySelect/current" className="btn btn-green  ms-5 me-5">Terminer ma commande</Link>
            </div>
          </>
        }
      </div>{selectedItem && <ProductModal product={selectedItem.product} onClose={handleCloseModal} canOrderProduct={false} />}
    </div >
  );
}
export default Cart;
